import "./HeaderView.css"
import MenuView from "./MenuView/MenuView";
import React, {CSSProperties, FC, useContext, useEffect, useState} from "react";
import {Power, CircleFill} from "react-bootstrap-icons";
import RandomSVGPicture from "../../Components/RandomSVGPicture/RandomSVGPicture";
import {Api} from "../../RequestsProvider/RequestProvider";
import moment from "moment";

interface Props {
    profilePictureURL?: string
}

const HeaderView = ({profilePictureURL}: Props) => {


    const [showUserMenu, setShowUserMenu] = useState(false)
    const [name, setName] = useState<string | null>(localStorage.getItem('apiKeyName'))

    const request = useContext(Api)

    const {updatedAt} = request!

    useEffect(() => {
        if (showUserMenu) {
            setTimeout(() => {
                setShowUserMenu(!showUserMenu)
            }, 4000)
        }
    }, [showUserMenu])

    const cssOverride: CSSProperties = {
        display: "block",
        marginTop: "20px",
        borderColor: "red",
    };

    const signOut = () => {
        localStorage.removeItem('apiKey')
        localStorage.removeItem('apiKeyName')
        window.location.reload()
    }

    //TODO die webseite aktualisiert aktuell natürlich auch jedes mal das updatedAt !!
    return (
        <>
            <div className="header">
                {<MenuView/>}
                <div className="user-info">
                    <div className="row">
                        <div className="col-12 name d-flex justify-content-end">{name}</div>
                        <div className="col-12 d-flex justify-content-end"><small
                            className={''}>Bot {moment().diff(updatedAt, 'minutes') > 15 ? 'offline' : 'online'}&nbsp;
                            <CircleFill
                                className={`${moment().diff(updatedAt, 'minutes') > 15 ? 'offline' : 'online'} pab-1`}
                                size={11}/></small>
                        </div>
                        {moment().diff(updatedAt, 'minutes') > 15 ?
                            <div className="col-12 d-flex justify-content-end position-relative pb-1"><small
                                className={'position-absolute'}>seit {moment(updatedAt).format('DD.MM.yyyy hh:ss')}</small>
                            </div> : null}
                    </div>
                    <div style={{position: "relative"}}>
                        <div className="user-picture" onClick={() => {
                            setShowUserMenu(!showUserMenu)
                        }}>
                            {profilePictureURL ? <div className="d-flex align-items-center">
                                <div className="circlef-colored">
                                    <img src={profilePictureURL} alt=""
                                         className="img-fluid rounded-circle"/>
                                </div>
                            </div> : <RandomSVGPicture {...{width: 45, height: 45}}/>}
                        </div>
                        {showUserMenu && (
                            <div className="user-menu-container" onClick={signOut}>
                            <div><Power></Power></div>
                                    <button>Logout</button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </>
            )
            }

            export default HeaderView
