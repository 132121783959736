import './BirthdayView.css'
import HeadNavigation from "../../Components/HeadNavigation/HeadNavigation";
import React, {SetStateAction, SyntheticEvent, useContext, useEffect, useRef, useState} from "react";
import CButton from "../../Components/Button/CButton";
import {
    ArrowRight, BalloonHeartFill,
    ChevronRight,
    DashSquareDotted, ExclamationDiamond,
    PlusSquareDotted,
    Search,
    XLg
} from "react-bootstrap-icons";
import who from "../../assets/images/who.png";
import noImage from "../../assets/images/noimage.png";
import {useNavigate} from "react-router-dom"
import Modal from "../../Components/Modal/Modal";
import {BarLoader} from "react-spinners";
import {toast} from "react-toastify";
import {Api, Contact} from "../../RequestsProvider/RequestProvider";
import Select from "react-select";

export interface Person {
    id: number,
    name: string,
    birthdate: string,
    tel: string,
    fromGroup: number,
    profilePictureURL: string | null,
    congratulateInChatOrGroup: string,
    congratulateOnBirthday: boolean,
    transcribeHidden: boolean
    groupID: string | null,
    created_at: string
    updated_at: string
}

interface Props {
    apiKey: string
}

interface Color {
    hex: string;
    hsl: [number, number, number];
}

export const formatAndCalculateAge = (birthdate: string) => {
    let [month, day, year] = birthdate.split("/");

    let formattedDate = `${day}.${month}.${year}`;

    let birthDateObj = new Date(Number(year), Number(month) - 1, Number(day));
    let ageDifMs = Date.now() - birthDateObj.getTime();
    let ageDate = new Date(ageDifMs);
    let age = Math.abs(ageDate.getUTCFullYear() - 1970)

    return {formattedDate, age};
}

export const BirthdayView = ({apiKey}: Props) => {

    const navigate = useNavigate()

    const [tableIsLoading, setTableIsLoading] = useState(false)
    const [showContactList, setShowContactList] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [employeeFilter, setEmployeeFilter] = useState("")
    const [contactFilter, setContactFilter] = useState("")
    const [birthdayData, setBirthdayData] = useState<Person[] | []>([])
    const [allContacts, setAllContacts] = useState<Contact[] | []>([])
    const [errorMessage, setErrorMessage] = useState("")
    const [profilePictureURL, setProfilePictureURL] = useState<string | null>(null)


    const addBirthdayNameRef = useRef<HTMLInputElement>(null);
    const addBirthdayDateRef = useRef<HTMLInputElement>(null);
    const addBirthdayNumberRef = useRef<HTMLInputElement>(null);

    const request = useContext(Api)
    const {getAllBirthday, getAllContacts} = request!

    const getAllPersons = async () => {

        const persons = await getAllBirthday()
        const contacts = await getAllContacts()
        setBirthdayData(persons.message);
        setAllContacts(contacts.message)
        setTimeout(() => setTableIsLoading(false), 600)
    };

    useEffect(() => {
        getAllPersons().then()
    }, [])
    useEffect(() => {
        setTimeout(() => setErrorMessage(""), 5000)
    }, [errorMessage])


    const colors: Color[] = [];

    const generateRandomHexColor = (): string => {
        const letters = "0123456789ABCDEF";
        let hex = "#";

        // Generate a random HEX color code
        for (let i = 0; i < 6; i++) {
            hex += letters[Math.floor(Math.random() * 16)];
        }

        // Convert the HEX code to HSL format
        const {h, s, l} = hexToHSL(hex);

        // Define the harmony distance between the colors
        const harmonyDistance = 30;

        // Calculate the harmonious colors


        if (colors.length > 1) {
            return colors[Math.floor(Math.random() * colors.length)].hex
        }
        for (let i = 0; i < 20; i++) {
            const newH = (h + i * harmonyDistance) % 360;
            const newS = Math.max(Math.min(s + i * 2, 100), 0);
            const newL = 57;
            // const newL = Math.max(Math.min(l - i * 2, 100), 0);
            const newHex = hslToHex(newH, newS, newL);
            colors.push({hex: newHex, hsl: [newH, newS, newL]});
        }
        //return {hex, hsl: [h, s, l], harmoniousColors: colors};
        return hex;
    }

    const hexToHSL = (hex: string): { h: number, s: number, l: number } => {
        hex = hex.replace("#", "");
        const r = parseInt(hex.substring(0, 2), 16) / 255;
        const g = parseInt(hex.substring(2, 4), 16) / 255;
        const b = parseInt(hex.substring(4, 6), 16) / 255;

        const max = Math.max(r, g, b);
        const min = Math.min(r, g, b);
        let h, s, l = (max + min) / 2;

        if (max === min) {
            h = s = 0;
        } else {
            const d = max - min;
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
            switch (max) {
                case r:
                    h = (g - b) / d + (g < b ? 6 : 0);
                    break;
                case g:
                    h = (b - r) / d + 2;
                    break;
                case b:
                    h = (r - g) / d + 4;
                    break;
            }
            // @ts-ignore
            h /= 6;
        }

        // @ts-ignore
        return {h: h * 360, s: s * 100, l: l * 100};
    }

    const hslToHex = (h: number, s: number, l: number): string => {
        const hue = h / 360;
        const sat = s / 100;
        const light = l / 100;

        const hueToRgb = (p: number, q: number, t: number): number => {
            if (t < 0) t += 1;
            if (t > 1) t -= 1;
            if (t < 1 / 6) return p + (q - p) * 6 * t;
            if (t < 1 / 2) return q;
            if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
            return p;
        };

        const q = light < 0.5 ? light * (1 + sat) : light + sat - light * sat;
        const p = 2 * light - q;

        const red = hueToRgb(p, q, hue + 1 / 3) * 255;
        const green = hueToRgb(p, q, hue) * 255;
        const blue = hueToRgb(p, q, hue - 1 / 3) * 255;

        const toHex = (c: number): string => {
            const hex = Math.round(c).toString(16);
            return hex.length === 1 ? "0" + hex : hex;
        };

        return `#${toHex(red)}${toHex(green)}${toHex(blue)}`;
    }

    const renderAddBirthdayModal = () => {

        const handleAddBirthdaySubmit = (event: SyntheticEvent) => {
            event.preventDefault();

            if (!addBirthdayNameRef.current?.value || !addBirthdayDateRef.current?.value || !addBirthdayNumberRef.current?.value) {
                setErrorMessage("Bitte fülle alle Felder aus!")
                return
            }

            const birthdate = addBirthdayDateRef.current?.value
            let tel = addBirthdayNumberRef.current?.value + '@c.us'
            if (!/^(0[1-9]|[12][0-9]|3[01])\.(0[1-9]|1[0-2])\.(19|20)\d{2}$/.test(birthdate)) {
                setErrorMessage("Bitte das Format dd.mm.yyyy im Geburtsdatum beachten!")
                return
            }
            if (tel.startsWith('0')) {
                setErrorMessage("Bitte das Format +49 (Ländercode) in der Telefonnummer beachten!")
                return
            }
            if (tel.startsWith('+')) {
                tel = tel.substring(1)
            }

            const data = {
                name: addBirthdayNameRef.current?.value,
                birthdate: birthdate,
                tel,
                fromGroup: 0,
                groupID: null,
                congratulateOnBirthday: "true",
                congratulateInChatOrGroup: "chat"
            }
            console.log(data)
            fetch(`http://${process.env.REACT_APP_API_ADDRESS}:${process.env.REACT_APP_API_PORT}/api/birthday/add`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-api-key': apiKey,
                    'x-api-dashboard-request': 'x'
                },
                body: JSON.stringify(data)
            }).then(resp => {
                if (resp.status === 200) {
                    console.log("employee added")
                    setShowModal(false)
                    toast(`${addBirthdayNameRef.current?.value} wurde erfolgreich hinzugefügt. 🥳`)
                    getAllBirthday().then()
                } else {
                    console.log(resp)
                    setErrorMessage("Etwas ist schief gelaufen.")
                }
            })

        }

        const handleSelectChange = (selectedOption: { value: Contact, label: any } | null) => {
            if (selectedOption) {
                console.log(selectedOption.value)
            }
        }
        const DivContainer = () => {

            const options = allContacts.map(contact => ({
                value: contact,
                label: customOptionRenderer(contact)
            })).filter(contact => !birthdayData.some(person => person.tel === contact.value.contactID))

            return <>
                <div className="modal-container overflow-hidden">
                    <img src={profilePictureURL ? profilePictureURL : who} alt=""
                         className={"img-fluid p-3 rounded-circle"} style={{maxWidth: "130px"}}/>
                    <h2 style={{color: '#3C3C3C'}}>Person hinzufügen</h2>
                    <form onSubmit={handleAddBirthdaySubmit}>
                        <div className={"position-relative"}>
                            <label htmlFor="name">Name</label>
                            <input required type="text" id="name" placeholder={"Vorname"} autoComplete="off"
                                   ref={addBirthdayNameRef} onChange={handleInputNameChange}
                                   onFocus={() => setShowContactList(true)}
                                   onBlur={() => setTimeout(() => setShowContactList(false), 100)}/>
                            {showContactList ? <div style={{maxHeight: "230px", minWidth: "100%"}}
                                                    className="bg-white position-absolute top-100 start-0 overflow-y-scroll">
                                {filteredContacts.map((contact) => {
                                    return contact.label
                                })}
                            </div> : null}
                        </div>
                        <div>
                            <label htmlFor="yearly">Geburtsdatum</label>
                            <input required type="text" placeholder={"dd.mm.yyyy"} id="yearly"
                                   ref={addBirthdayDateRef}/>
                        </div>
                        <div>
                            <label htmlFor="number">Nummer</label>
                            <input required type="number" placeholder={"Handynummer mit Ländercode (+49)"} id="number"
                                   ref={addBirthdayNumberRef}/>
                        </div>

                        <div className="errorMessage">
                            {errorMessage}
                            <br/>
                            {errorMessage && (<ExclamationDiamond color={"#DB2742"} size={".8em"}/>)}
                        </div>
                        <button className={"modal-container-button"} type={"submit"}>Speichern</button>
                    </form>
                </div>
            </>
        }
        return <Modal showModal={showModal} setShowModal={setShowModal} content={DivContainer()}/>
    }
    const handleBirthdaySearchChange = (event: { target: { value: SetStateAction<string>; }; }) => {
        setEmployeeFilter(event.target.value)
    }
    const handleInputNameChange = (event: { target: { value: SetStateAction<string>; }; }) => {
        setProfilePictureURL(null)
        setContactFilter(event.target.value)
        addBirthdayNumberRef.current!.value = ''
    }
    const filteredBirthdays = birthdayData.filter((data: Person) => {

        return data.name.toLowerCase().includes(employeeFilter.toLowerCase())
    })

    const customOptionRenderer = (selectedOption: Contact) => {

        const [firstName, ...rest] = selectedOption.name.split(" ")
        const lastName = rest.join(" ")

        const setInfos = () => {
            addBirthdayNameRef.current!.value = firstName
            addBirthdayNumberRef.current!.value = "49" + selectedOption.number.substring(1)
            setProfilePictureURL(selectedOption.profilePictureURL)
        }
        return (
            <div key={selectedOption.id} className="card mb-3" onClick={() => {
                setInfos()
            }}>
                <div className="row g-0">
                    <div className="col-md-4 col-5">
                        <img
                            src={selectedOption.profilePictureURL.length > 1 ? selectedOption.profilePictureURL : noImage}
                            alt="profilePictureURL"
                            className="img-fluid p-3 rounded-circle"/>
                    </div>
                    <div className="col-md-8 align-content-center col-7">
                        <div className="card-body">
                            <h5 className="card-title text-body-secondary">{firstName}</h5>
                            <span className="card-text"><small className="text-body-secondary">{lastName}</small></span>
                            <p className="card-text"><small
                                className="text-body-secondary">{selectedOption.number}</small></p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    const filteredContacts = allContacts.map(contact => ({
        value: contact,
        label: customOptionRenderer(contact)
    })).filter(contact => !birthdayData.some(person => person.tel === contact.value.contactID))
        .filter(contact => contact.value.name.toLowerCase().includes(contactFilter.toLowerCase()))


    const renderDeleteIcon = () => employeeFilter ?
        <XLg onClick={() => setEmployeeFilter("")} className="delete-icon"/> : null
    return (
        <>
            <HeadNavigation path={[{path: "/", pathName: "Startseite"}, {path: null, pathName: "Personen"}]}/>
            <div className="grid-padding">
                <div className="site-header d-flex justify-content-between align-items-center">
                    <h1>Personen <span>[{filteredBirthdays.length}]</span></h1>
                    <CButton content={"Person hinzufügen"} icon={<ArrowRight/>} onClick={() => setShowModal(true)}/>
                </div>
                {renderAddBirthdayModal()}
                <div className="search-input">
                    <Search className="search-icon"/>
                    <input value={employeeFilter} placeholder={"Suche nach Name"} className="employee-search"
                           type="text"
                           onChange={handleBirthdaySearchChange}/>
                    {renderDeleteIcon()}
                </div>
                <div style={{height: 'calc(80vh - 50px)', overflowY: 'auto', overflowX: 'hidden'}}>
                    <table className={"mt-2"}>
                        <thead>
                        <tr>
                            <th>Name</th>
                            <th>Geburtstag</th>
                            <th>Nummer</th>
                            <th>Aktiv</th>
                        </tr>
                        </thead>
                        <tbody>
                        {tableIsLoading ? (
                            <>
                                <tr>
                                    <td colSpan={4} style={{padding: "10% 0 0 0", textAlign: "center"}}>
                                        Lade Daten...
                                    </td>
                                </tr>
                                <tr>
                                    <td colSpan={4} style={{padding: 0, textAlign: "center"}}>
                                        <BarLoader
                                            color={'black'}
                                            height={2}
                                            width={"20%"}
                                            cssOverride={{
                                                display: "block",
                                                margin: "0 auto",
                                                backgroundColor: "transparent",
                                                borderColor: "red",
                                                color: "red"
                                            }}
                                            speedMultiplier={.8}
                                        />
                                    </td>
                                </tr>
                            </>
                        ) : (
                            filteredBirthdays.length > 0 ? filteredBirthdays.map((item: Person) => {
                                    const initials = item.name.split(" ").map((word: string) => word.charAt(0)).join("").toUpperCase()
                                    const formattedBirthdate = formatAndCalculateAge(item.birthdate)
                                    const number = item.tel
                                    let mod_tel = number.replace(/^49/, "0")
                                    mod_tel = mod_tel.replace(/@c\.us$/, "")
                                    mod_tel = mod_tel.slice(0, 4) + " " + mod_tel.slice(4)
                                    return (
                                        <tr key={item.id} className="table-row" onClick={() => {
                                            navigate(`/personen/p/${item.id}?name=${item.name}`)
                                        }}>
                                            <td className="border-radius-left">
                                                {item.profilePictureURL ? <div className="d-flex align-items-center">
                                                    <div className="circle-colored">
                                                        <img src={item.profilePictureURL} alt=""
                                                             className="img-fluid rounded-circle"/>
                                                    </div>
                                                    <span className="ms-2">{item.name}</span>
                                                </div> : <div className="flex">
                                                    <div style={{background: generateRandomHexColor()}}
                                                         className="circle-colored">
                                                        <div>{initials}</div>
                                                    </div>
                                                    <span>{item.name}</span>
                                                </div>}
                                            </td>
                                            <td className={"text-truncate"}>{formattedBirthdate.formattedDate} <span
                                                className={"text-secondary"}>({formattedBirthdate.age})</span></td>
                                            <td className={"text-truncate"}>{mod_tel}</td>
                                            {item.congratulateOnBirthday ? (
                                                <td className={"is-active"}><PlusSquareDotted/></td>) : (
                                                <td className={"is-inactive"}><DashSquareDotted/></td>)}
                                            <td className="border-radius-right"><ChevronRight/></td>
                                        </tr>

                                    )
                                }) :
                                <>
                                    <tr>
                                        <td colSpan={4} style={{padding: "10% 0 0 0", textAlign: "center"}}>
                                            <BalloonHeartFill size={"100px"} color={"#aaa"}/>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colSpan={4} className="empty-employee-list-msg">
                                            Es sind noch keine Personen hinterlegt.
                                        </td>
                                    </tr>
                                </>
                        )}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    )
}