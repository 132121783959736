import React, { useEffect, useState } from "react";
import './GetAPIKey.css';
import RandomSVGPicture from "../../Components/RandomSVGPicture/RandomSVGPicture";
import {Spinner} from "react-bootstrap";
import {BarLoader} from "react-spinners";

export const GetAPIKey = () => {
    const [errorCode, setErrorCode] = useState<string | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isValid, setIsValid] = useState<boolean | undefined>(undefined);
    const [isRequestingApiKey, setIsRequestingApiKey] = useState<boolean>(false);
    const [apiKey, setApiKey] = useState("");

    const verifyAPIKey = async () => {
        setIsRequestingApiKey(true);
        setErrorCode(null); // Reset error code
        setTimeout(async () => {
            try {
                const response = await fetch(
                    `http://${process.env.REACT_APP_API_ADDRESS}:${process.env.REACT_APP_API_PORT}/api`,
                    {
                        headers: {
                            "x-api-key": apiKey,
                            'x-api-dashboard-request':'x'
                        },
                    }
                );
                const dat = await response.json();

                console.log(dat);
                if (response.status === 403) {
                    setIsValid(false);
                    setIsRequestingApiKey(false);
                } else if (response.status === 200) {
                    setIsValid(true);
                    setTimeout(() => {
                        localStorage.setItem('apiKey', apiKey);
                        localStorage.setItem('apiKeyName', dat.message.name);
                        localStorage.setItem('expiresAt', dat.message.expiresAt);
                        window.location.reload();
                    }, 600);
                } else {
                    setErrorCode(dat.message);
                    setIsRequestingApiKey(false)
                }
            } catch (error) {
                console.error(error);
                setErrorCode("An error occurred while verifying the API key.");
                setIsRequestingApiKey(false);
            }
        },500)
    };

    useEffect(() => {
        if (isValid !== undefined) {
            const timeout = setTimeout(() => {
                setIsValid(undefined);
            }, 2000);
            return () => clearTimeout(timeout);
        }
    }, [isValid]);

    useEffect(() => {
        if(errorCode){
            setTimeout(()=>{setErrorCode(null)},1400)
        }
    }, [errorCode]);

    return (
        <div className="login" style={{ position: "fixed" }}>
            <h1>
                <RandomSVGPicture width={100} height={100} rotate={true} /><br />
                Login
            </h1>
            <form onSubmit={(e) => { e.preventDefault(); verifyAPIKey().then(); }}>
                <input
                    disabled={isRequestingApiKey}
                    type="text"
                    name="u"
                    placeholder="API Key"
                    onChange={(e) => setApiKey(e.target.value)}
                />
                {isRequestingApiKey? <BarLoader
                    color={'#4ceb9c'}
                    height={3}
                    width={"100%"}
                    cssOverride={{backgroundColor: "transparent"}}
                    speedMultiplier={.8}
                /> : null}
                <div className="errorCode mt-2">{errorCode}</div>
                <button type="submit" className="btn btn-primary btn-block btn-large">
                    {isRequestingApiKey? <Spinner size={'sm'}/> : 'Let me in.'}
                </button><br />
            </form>
        </div>
    );
};
